<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { authUserMethods } from "../../../state/helpers/authuser";
// import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import _ from "lodash";
import { required } from "vuelidate/lib/validators";
import { propertyMethods } from "../../../state/helpers/property";
import { paymentCategoryMethods } from "../../../state/helpers/paymentcategory";
import { paymentMethods } from "../../../state/helpers/payment";
import { formOfPaymentMethods } from "../../../state/helpers/formofpayment";
import Swal from "sweetalert2";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { PDFDocument } from "pdf-lib";
import pdfImage from "../../../assets/images/pdf.png";
import { currencyMixin } from "../../mixins/currencyMixin";
import { formatCurrencyMixin } from "../../mixins/formatCurrencyMixin";
import { formatDateMixin } from "../../mixins/formatDateMixin";
import validateObjectMixin  from "../../mixins/validateObjectMixin";
/**
 * Blank page component
 */

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  page: {
    title: "Payments",
    meta: [{ name: "description", content: appConfig.description }],
  },
  // components: { ckeditor: CKEditor.component, Layout },
  components: { Layout, DatePicker },
  mixins: [currencyMixin, formatCurrencyMixin, formatDateMixin, validateObjectMixin],
  data() {
    return {
      fileRecords: [],
      pdfImage,
      file: {},
      is_sort_asc: false,
      is_sort_ascdesc_enable: false,
      active_column: '',
      uploadUrl: "https://www.mocky.io/v2/5d4fb20b3000005c111099e3",
      uploadHeaders: { "X-Test-Header": "vue-file-agent" },
      fileRecordsForUpload: [],
      errorText: {
        type: "Invalid file type. Only images Allowed",
        size: "Files should not exceed 10MB in size",
      },
      currentDate: new Date(),
      monthList: [],
      pdfBytes: null,
      pdfSrc: null,
      payment: {
        memo: "",
        payment_amount: "",
        purpose_of_payment: null,
        form_of_payment: null,
        payment_date: null,
        payment_form_number: null,
        date_deposited: null,
        deposited_by: "tenant",
        deposited_by_id: null,
        date_confirmed: null,
        confirmed_by: null,
        property_id: null,
      },
      property: null,
      tenant: null,
      staff: null,
      confirmed_by_staff: null,
      editor: ClassicEditor,
      editorConfig: {
        toolbarLocation: "bottom",
      },
      options: [],
      tenants: [],
      staffs: [],
      confirmed_by_staffs: [],
      tab_items: [
        { id: 1, title: "Payment" },
        { id: 2, title: "Payment Status" },
        // { id: 3, title: "Receipt" },
      ],
      title: "Payments",
      is_tab_active: 1,
      is_item_active: true,
      is_update: false,
      submitted: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      selected_month_year: null,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      can_login: false,
      payments: [],
      categories: [],
      form_of_payments: [],
      start_date: null,
      end_date: null,
      payment_number_label: "Payment Number",
      payment_date_label: "Payment Date",
      deposit_by_label: "Deposited By",
      receipt: {
        file: "",
      },
      fields: [
        { key: "id",label: "Transaction Number" },
        { key: "property_name", sortable: true },
        { key: "payment_date", label: "Payment Date"},
        { key: "payment_amount"},
        { key: "receive_from" },
        { key: "memo", label: "Memo"},
      ],
      search_options: [
        {id: 1, type: "Search payment by date range(start date and end date) "},
        {id: 2, type: "Search payment for date range with property."},
        {id: 3, type: "Search payment for date range with tenant."},
        {id: 4, type: "Search paymen by date range with property and tenant"},
        {id: 5, type: "Search payment for Month/Year"},
        {id: 6, type: "Search payment for Month/Year with property"},
        {id: 7, type: "Search payment for Month/Year with tenant"},
        {id: 8, type: "Search payment for Month/Year with property and tenant"},
        {id: 9, type: "Search payment for Property Only"},
        {id: 10, type: "Search payment for Tenant Only"},
      ],
      active_search_option: 1,
      month: null,
      year: null,
    };
  },
  validations: {
    payment: {
      payment_amount: { required },
      purpose_of_payment: { required },
      form_of_payment: { required },
      payment_date: { required },
      payment_form_number: { required },
      date_deposited: { required },
      deposited_by: { required },
      date_confirmed: { required },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    formattedPaymentAmount: {
      get() {
        const amount = parseFloat(this.payment.payment_amount);
        if (!isNaN(amount)) {
          return "$" + amount.toFixed(2);
        }
        return this.payment.payment_amount; // Return original input if not a valid number
      },
      set(value) {
        // Remove non-numeric characters except dot
        this.payment.payment_amount = value.replace(/[^0-9.]/g, "");
      },
    },
   
  },
  mounted() {
    this.getAllPayment();
    this.getAllPaymentCategory();
    this.getAllFormOfPayment();
    this.generateMonthList();
    // Set the initial number of items
    // this.totalRows = this.items?.length;
  },
  methods: {
    ...authUserMethods,
    ...propertyMethods,
    ...paymentMethods,
    ...paymentCategoryMethods,
    ...formOfPaymentMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    uploadFiles() {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(
        this.uploadUrl,
        this.uploadHeaders,
        this.fileRecordsForUpload
      );
      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile(fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );

      this.receipt = {
        file: "",
      };
    },
    async loadPDF() {
      const pdf = process.env.VUE_APP_STORAGE_PATH + this.payment.receipt;

      // Load the PDF file
      const response = await fetch(pdf);
      const pdfData = await response.arrayBuffer();
      this.pdfBytes = new Uint8Array(pdfData);

      // Create a PDF document instance
      const pdfDoc = await PDFDocument.load(this.pdfBytes);

      // Get the first page of the PDF
      // eslint-disable-next-line no-unused-vars
      const firstPage = pdfDoc.getPage(0);

      // Serialize the modified PDF back to bytes
      const modifiedPdfBytes = await pdfDoc.save();

      // Convert the modified PDF bytes to a base64 data URL
      this.pdfSrc = `data:application/pdf;base64,${Buffer.from(
        modifiedPdfBytes
      ).toString("base64")}`;
      this.$bvModal.show("pdf-modal");
    },
    openPDF() {
      this.$bvModal.show("pdf-modal");
    },
    filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
        validFileRecords
      );

      const file = this.fileRecordsForUpload;
      const dom = this;
      // eslint-disable-next-line func-names
      return (async function(file) {
        const data = await toBase64(file[0]);
        dom.receipt.file = data;
      })(file);
    },
    onBeforeDelete(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        const k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else if (confirm("Are you sure you want to delete?")) {
        // eslint-disable-next-line no-console
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
      }
    },
    fileDeleted(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },

    getFileName(file) {
      const fileData = file.split("/");
      const lastElement = fileData[fileData.length - 1];
      return lastElement;
    },
    getAllPayment() {
      return this.allPayment({ paginate: true }).then((response) => {
        // eslint-disable-next-line no-console
        this.payments = response.data.data;
        this.totalRows = response.data.total;
      });
    },
    handlePageChange(page) {
      const field = this.active_column
      this.is_sort_asc = !this.is_sort_asc
      let query;
      if(field != '') {
        query = { paginate: true,  ascdesc_sort: this.is_sort_asc ? 'asc' : 'desc', page, field}
      }else{
        query = { paginate: true, page }
      }

      return this.allPayment(query).then((response) => {
        // eslint-disable-next-line no-console
        this.payments = response.data.data;
        this.totalRows = response.data.total;
      });
    },
    getAllPaymentCategory() {
      return this.allPaymentCategory({ paginate: false }).then((response) => {
        // eslint-disable-next-line no-console
        this.categories = response.data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      });
    },
    getAllFormOfPayment() {
      return this.allFormOfPayment({ paginate: false }).then((response) => {
        // eslint-disable-next-line no-console
        this.form_of_payments = response.data.sort((a, b) => a.form_of_payment.toLowerCase().localeCompare(b.form_of_payment.toLowerCase()));
      });
    },
    showModal() {
      this.$bvModal.show("app-modal");
      if (this.fileRecordsForUpload.length > 0) {
        this.$refs.vueFileAgent.deleteFileRecord(this.fileRecordsForUpload[0]);
      }
    },
    hide() {
      this.getAllPayment();
      this.$bvModal.hide("app-modal");
      this.is_update = false;
      this.is_tab_active = 1;
      // const tab_view = this.tab_items.filter(item => item.id !== 3);
      // this.tab_items = tab_view;
      if (this.fileRecordsForUpload.length > 0) {
        this.$refs.vueFileAgent.deleteFileRecord(this.fileRecordsForUpload[0]);
      }
      this.emptyData();
      this.property = null;
      (this.options = []), (this.tenants = []);
      this.tenant = null;
    },
    showFormContent(id) {
      this.is_tab_active = id;
    },
    showDetails(item) {
      const data = item;

      this.is_update = true;
      this.is_tab_active = 1;
      this.showProperty(item.property_id).then((response) => {
        // eslint-disable-next-line no-console
        this.property = response.data;
        this.payment = data;
        this.payment.date = moment(item.date).toDate();
        this.payment.payment_date = moment(item.payment_date).toDate();
        this.payment.date_deposited = moment(item.date_deposited).toDate();
        this.payment.date_confirmed = moment(item.date_confirmed).toDate();
        this.showModal();
      });

      this.showTenant(item.tenant_id).then((response) => {
        this.tenant = response.data;
      });

      this.showStaff(item.confirmed_by).then((response) => {
        this.confirmed_by_staff = response.data;
      });

      if(item.deposited_by === 'staff'){
        this.showStaff(item.deposited_by_id).then((response) => {
        this.staff = response.data;
      });

      }


      // const tab_view = this.tab_items.filter(item => item.id === 3);
      // if(tab_view.length < 1) {
      //   this.tab_items.push({ id: 3, title: "Status" })
      // }
    },
    savePayment() {
      this.submitted = true;
      // eslint-disable-next-line no-console
      // stop here if form is invalid
      const importantData = this.payment;

      if(this.property === null){
        importantData.property_id = null
      }else{
        delete importantData.property_id;
      }

      if(importantData.deposited_by === 'tenant'){

        if(this.tenant === null){
        importantData.deposited_by_id = null
      }else{
        delete importantData.deposited_by_id ;
      }
      }else{
        if(this.staff === null){
        importantData.deposited_by_id = null
      }else{
   
        delete importantData.deposited_by_id ;
      }
      }
      


      if(this.confirmed_by_staff === null){
        importantData.confirmed_by = null
      }else{
        delete importantData.confirmed_by;
      }

      const validateObject = this.validateObjectData(importantData)

      this.$v.$touch();
      if(validateObject.length > 0){return}
      if (this.$v.$invalid) {
        return;
      } else {
        const data = this.payment;
        // eslint-disable-next-line no-console
        if(this.payment.deposited_by === "staff"){
          data.deposited_by_id = this.staff.id
        } else if(this.payment.deposited_by === "tenant") {
          data.deposited_by_id = this.tenant.id
        }
        data.property_id = this.property.id;
        data.confirmed_by = this.confirmed_by_staff.id;

        data.tenant_id = this.tenant.id;
        // if (this.receipt.file !== "") {
        //   data.receipt = [this.receipt];
        // }
        data.payment_date = moment(this.payment.payment_date).format(
          "YYYY-MM-DD"
        );
        data.date_confirmed = moment(this.payment.date_deposited).format(
          "YYYY-MM-DD"
        );
        this.createPayment(data).then(() => {
          this.hide();
         
        });
      }
      this.submitted = false;
    },
    updatePaymentDetails() {
      this.submitted = true;
      // stop here if form is invalid
      const importantData = this.payment;

if(this.property === null){
  importantData.property_id = null
}else{
  delete importantData.property_id;
}

if(importantData.deposited_by === 'tenant'){

  if(this.tenant === null){
  importantData.deposited_by_id = null
}else{
  delete importantData.deposited_by_id ;
}
}else{
  if(this.staff === null){
  importantData.deposited_by_id = null
}else{

  delete importantData.deposited_by_id ;
}
}



if(this.confirmed_by_staff === null){
  importantData.confirmed_by = null
}else{
  delete importantData.confirmed_by;
}

const validateObject = this.validateObjectData(importantData)

this.$v.$touch();
if(validateObject.length > 0){return}


      if (this.$v.$invalid) {
        return;
      } else {
        const data = this.payment;
        // eslint-disable-next-line no-console
        if(this.payment.deposited_by === "staff"){
          data.deposited_by_id = this.staff.id
        } else if(this.payment.deposited_by === "tenant") {
          data.deposited_by_id = this.tenant.id
        }
        data.property_id = this.property.id;
        data.confirmed_by = this.confirmed_by_staff.id;

        data.tenant_id = this.tenant.id;
        // if (this.receipt.file !== "") {
        //   data.receipt = [this.receipt];
        // }
        data.payment_date = moment(this.payment.payment_date).format(
          "YYYY-MM-DD"
        );
        data.date_confirmed = moment(this.payment.date_deposited).format(
          "YYYY-MM-DD"
        );
        this.updatePayment(data).then(() => {
          this.hide();
          
        });
      }
      this.submitted = false;
    },
    emptyData() {
      this.payment ={
        memo: "",
        payment_amount: "",
        purpose_of_payment: null,
        form_of_payment: null,
        payment_date: null,
        payment_form_number: null,
        date_deposited: null,
        deposite_by: "tenant",
        deposited_by_id: null,
        date_confirmed: null,
        confirmed_by: null,
        property_id: null,
      };
      this.receipt = {
        file: "",
      };
      this.property = null;
      this.tenant = null;
      this.staff = null;
      this.confirmed_by_staff = null;
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    // eslint-disable-next-line no-unused-vars
    search: _.debounce((loading, search, vm) => {
      vm.allProperty({ paginate: true, keyword: search }).then((response) => {
        // eslint-disable-next-line no-console
        vm.options = response.data.data;
        loading(false);
      });
    }, 100),
    onTenantSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.searchTenant(loading, search, this);
      }
    },
    // eslint-disable-next-line no-unused-vars
    searchTenant: _.debounce((loading, search, vm) => {
      vm.allTenants({ paginate: true, keyword: search }).then((response) => {
        // eslint-disable-next-line no-console
        vm.tenants = response.data.data;
        loading(false);
      });
    }, 100),
    onStaffSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.searchStaff(loading, search, this);
      }
    },
    // eslint-disable-next-line no-unused-vars
    searchStaff: _.debounce((loading, search, vm) => {
      vm.allStaffs({ paginate: true, keyword: search }).then((response) => {
        // eslint-disable-next-line no-console
        vm.staffs = response.data.data;
        loading(false);
      });
    }, 100),
    onConfirmedByStaffSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.searchConfirmedByStaff(loading, search, this);
      }
    },
    // eslint-disable-next-line no-unused-vars
    searchConfirmedByStaff: _.debounce((loading, search, vm) => {
      vm.allStaffs({ paginate: true, keyword: search }).then((response) => {
        // eslint-disable-next-line no-console
        vm.confirmed_by_staffs = response.data.data;
        loading(false);
      });
    }, 100),
    toggleSwitch(item) {
      const data = this.payment;
      // eslint-disable-next-line no-console
      if (item.value === true) {
        data.status = "active";
      } else if (item.value === false) {
        data.status = "canceled";
      }
      this.markAsCancel(this.payment).then(() => {
        this.getAllPayment();
      });
    },
    getFullDate(date) {
      return moment(date).format("Do MMMM, YYYY");
    },
    deletePaymentItem() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deletePayment(this.payment.id).then(() => {
            this.getAllPayment();
            this.hide();
          });
        }
      });
    },
    formOfPaymentChange(event) {
      const id = event.target.value;

      const data = this.form_of_payments.filter((elm) => id == elm.id)[0];
      const sentence = data.form_of_payment;
      const words = sentence.split(" ");
      const lastWord = words[words.length - 1];
      // eslint-disable-next-line no-console
      if (lastWord === "Check") {
        this.payment_number_label = "Check Number";
        this.payment_date_label = "Check Date";
        this.deposit_by_label = "Deposit by";
      } else if (lastWord === "Order") {
        this.payment_number_label = "Money Order Number";
        this.payment_date_label = "Issue Date";
        this.deposit_by_label = "Deposit by";
      } else if (data.type === "electronic") {
        this.payment_number_label = "Transaction Number";
        this.payment_date_label = "Transfer Date";
        this.deposit_by_label = "Deposit by";
      }
    },
    handleDepositedByChange(event) {
      this.payment.deposited_by = event.target.value;
    },
    filterPaymentWithProperty(data) {
      // eslint-disable-next-line no-console
      const active_search = this.active_search_option; 
      if (data === null) {
        return this.getAllPayment();
      }

      // eslint-disable-next-line no-console
      if(active_search == 9) {
        this.allPaymentForProperty({
        paginate: true,
        property_id: data.id,
      }).then((response) => {
        // eslint-disable-next-line no-console
        this.payments = response.data.data;
        this.totalRows = response.data.total;
      });
      } else if(active_search == 2 || active_search == 4) {
        return this.getAllPaymentWithDateRange()
      } else if(active_search == 6 || active_search == 8) {
        return this.getAllPaymentWithMonthYear()
      }

   
    },
    filterPaymentWithTenant(data) {
      // eslint-disable-next-line no-console
      const active_search = this.active_search_option; 
      if (data === null) {
        return this.getAllPayment();
      }
      if(active_search == 10) {
        return this.allPaymentForTenant({ tenant_id: data.id }).then(
        (response) => {
          // eslint-disable-next-line no-console
          this.payments = response.data.data;
          this.totalRows = response.data.total;
        })
      } else if(active_search == 3 || active_search == 4) {
        return this.getAllPaymentWithDateRange()
      } else if(active_search == 7 || active_search == 8) {
        return this.getAllPaymentWithMonthYear()
      }
    },
    getAllPaymentWithDateRange(){
      let data;
      const active_search = this.active_search_option; 
      if(active_search == 1) {
        data = { start_date: this.start_date, end_date: this.end_date }
      } else if(active_search == 2) {
        if(this.property === null) return;
        data = { start_date: this.start_date, end_date: this.end_date, property_id: this.property.id }
      } else if(active_search == 3) {
        if(this.tenant == null) return;
        data = { start_date: this.start_date, end_date: this.end_date, tenant_id: this.tenant.id }
      } else if(active_search == 4){
        if(this.tenant === null || this.property === null) return;
        data = { start_date: this.start_date, end_date: this.end_date, tenant_id: this.tenant.id, property_id: this.property.id }
      }
      this.allPaymentWithDateRange(data).then((response) => {
        // eslint-disable-next-line no-console
        this.payments = response.data.data;
        this.totalRows = response.data.total;
      });
    },
    getAllPaymentWithMonthYear(){
      let data;
      const active_search = this.active_search_option; 
      if(active_search == 5) {
        data = { month: this.month, year: this.year }
      } else if(active_search == 6) {
        if(this.property == null) return;
        data = { month: this.month, year: this.year, property_id: this.property.id }
      } else if(active_search == 7) {
        if(this.tenant === null) return;
        data = { month: this.month, year: this.year, tenant_id: this.tenant.id }
      } else if(active_search == 8){
        if(this.tenant === null || this.property === null) return;
        data = { month: this.month, year: this.year, tenant_id: this.tenant.id, property_id: this.property.id }
      }

      return this.allPaymentWithMonthYear(data).then((response) => {
        // eslint-disable-next-line no-console
        this.payments = response.data.data;
        this.totalRows = response.data.total;
      });
    },
    filterPaymentWithStartDateEndDate() {
      // eslint-disable-next-line no-console
      if (this.start_date === null && this.end_date === null) {
        return this.getAllPayment();
      }
      if (this.start_date === null) return;
      if (this.end_date === null) return;
    
      return this.getAllPaymentWithDateRange()
    },
    filterPaymentWithMonthYear(event) {
      if (this.selected_month_year === null) {
        return this.getAllPayment();
      }

      const data = event.target.value;
      const dateArray = data.split("/");
      this.month = dateArray[0];
      this.year = dateArray[1];

      return this.getAllPaymentWithMonthYear();
    },
    generateMonthList() {
      const startMonth = this.currentDate.getMonth();
      const startYear = this.currentDate.getFullYear();

      this.monthList = [];

      let currentMonth = startMonth;
      let currentYear = startYear;

      for (let i = 0; i < 13; i++) {
        const month = new Date(currentYear, currentMonth);
        this.monthList.push(moment(month).format("MMM/YYYY"));

        if (currentMonth === 11) {
          currentMonth = 0;
          currentYear++;
        } else {
          currentMonth++;
        }
      }
    },

    goToPreviousMonth() {
      this.currentDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() - 1,
        1
      );
      this.selected_month_year = moment(this.currentDate).format("MMM-YYYY")
      this.generateMonthList();
    },
    goToNextMonth() {
      this.currentDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        1
      );
      this.selected_month_year = moment(this.currentDate).format("MMM-YYYY")
      this.generateMonthList();
    },
    formatMonth(month) {
      const options = { month: "long", year: "numeric" };
      const formattedDate = month.toLocaleDateString(undefined, options);
      const [formattedMonth, formattedYear] = formattedDate.split(" ");
      return `${formattedMonth} ${formattedYear}`;
    },
    handleSearchOption(event){
      // eslint-disable-next-line no-console
      this.active_search_option = event.target.value;
      this.property = null;
      this.tenant = null;
      this.start_date = null;
      this.end_date = null;
      this.selected_month_year = null;
      this.getAllPayment();
    },
    async handleTableHeadClicked(item) {
      this.active_column = item
      this.is_sort_asc = !this.is_sort_asc

      let query = { paginate: true,  ascdesc_sort: this.is_sort_asc ? 'asc' : 'desc', page: this.page, field: item}
    
      await this.allPayment(query).then((response) => {
        // eslint-disable-next-line no-console
        this.payments = response.data.data;
        this.totalRows = response.data.total;
      });
    },
    handlePropertyChange(data) {
     /*eslint-disable*/ 
    if(data == null){
      this.property = null
    }
  },
  handleStaffChange(data) {
     /*eslint-disable*/ 
    if(data == null){
      this.staff = null
    }
  },
  handleConfirmedByChange(data) {
     /*eslint-disable*/ 
    if(data == null){
      this.confirmed_by_staff = null
    }
  },
  handleTenantChange(data) {
     /*eslint-disable*/ 
    if(data == null){
      this.tenant = null
    }
  }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-md-8">
        <b-card no-body class="mt-5">
          <b-card-body>
            <b-card-title
              class="d-flex mb-5 align-items-center justify-content-between"
            >
              <h1 class="card-title">Payment List</h1>
              <a
                href="javascript:void(0);"
                class="btn btn-outline-success btn-block"
                @click="showModal"
                >Add New</a
              >
            </b-card-title>

            <!-- <b-card-title
              class="d-flex mb-3 align-items-center justify-content-between"
            >
            <div class="search_option_input_view">
              <label class="form-label mt-2" for="item1">Search Option for Payment</label>
            <select v-model="active_search_option" class="form-control" @change="handleSearchOption">
              <option v-for="{id, type}  of search_options" :value="id" :key="id"> {{ type }} </option>
            </select>
            </div>

          </b-card-title> -->

          <!-- <div class="search_input_option_view">
            <div class="date_range_view" v-if="active_search_option == 1 || active_search_option == 2 ||
            active_search_option == 3 || active_search_option == 4">
              <div class="split">
                <label class="form-label mt-2" for="item1">Start Date</label>
                  <date-picker
                    id="item1"
                    v-model="start_date"
                    format="DD MMMM, YYYY"
                    class=""
                    lang="en"
                    @change="filterPaymentWithStartDateEndDate"
                  ></date-picker>
              </div>
              <div class="split">
                <label class="form-label mt-2" for="item1">End Date</label>
                  <date-picker
                    id="item1"
                    v-model="end_date"
                    format="DD MMMM, YYYY"
                    class=""
                    lang="en"
                    @change="filterPaymentWithStartDateEndDate"
                  ></date-picker>
              </div>
            </div>
            <div class="month_year_view" v-if="active_search_option == 5 ||  active_search_option == 6 || active_search_option == 7 || active_search_option == 8">
              <label class="form-label mt-2" for="item1">Month/Year</label>
              <div class="col-md-12 d-flex align-items-center">
                  <button
                    type="button"
                    class="btn btn-outline-success"
                    @click="goToPreviousMonth"
                  >
                    Prev Month
                  </button>
                  <select
                    id="item1"
                    v-model="selected_month_year"
                    class="form-select form-control join_select_input"
                    @change="filterPaymentWithMonthYear"
                  >
                    <option
                      v-for="(month, index) in monthList"
                      :key="index"
                      :value="month"
                      >{{ month }}</option
                    >
                  </select>
                  <button
                    type="button"
                    class="btn btn-outline-success"
                    @click="goToNextMonth"
                  >
                    Next Month
                  </button>
                </div>
            </div>
            <div class="property_search_view" v-if="
            active_search_option == 9 || active_search_option == 2 || active_search_option == 4 || active_search_option == 6 || active_search_option == 8">
              <label class="form-label mt-2" for="item1"
                    >Property</label
                  >
                  <v-select
                    v-model="property"
                    label="property_name"
                    :filterable="false"
                    :options="options"
                    @search="onSearch"
                    @input="filterPaymentWithProperty"
                    class=""
                  >
                    <template slot="no-options">
                      type to search property
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.property_name }}
                      </div>
                    </template>
                  </v-select>
            </div>
            <div class="tenant_search_view" v-if="active_search_option == 10 || active_search_option == 3 || active_search_option == 4 || active_search_option == 7 || active_search_option == 8">
              <label class="form-label mt-2" for="item1"
                    >Tenant</label
                  >
                  <v-select
                    v-model="tenant"
                    label="full_name"
                    :filterable="false"
                    :options="tenants"
                    @search="onTenantSearch"
                    @input="filterPaymentWithTenant"
                    class=""
                  >
                    <template slot="no-options">
                      type tenant email to search tenant
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.full_name }}
                      </div>
                    </template>
                  </v-select>
            </div>
          </div> -->

            <b-card-text>
              <!-- Table -->
              <div class="table-responsive mb-0 datatables payment_table">
                <b-table
                  :items="payments"
                  :fields="fields"
                  responsive="sm"
                  id="app-table"
                striped="striped"
                borderless="borderless"
                 outlined="outlined"
                 fixed="fixed"
                 sticky-header="true"
                  @row-clicked="showDetails"
                  @head-clicked="handleTableHeadClicked"
                >
                <template #head()="data">
        <div class="table_heading_view">
          <span class="">{{ data.label}}</span>
        <i class="fas fa-sort-amount-down-alt  text-success" id="sort-2-tooltip" v-if="is_sort_asc && data.column === active_column"></i>
        <i class="fas fa-sort-amount-up  text-info" id="sort-2-tooltip" v-if="!is_sort_asc && data.column === active_column"></i>
        </div>
        
      </template>
                  <template #cell(payment_date)="data">
                    {{ formDate(data.value) }}
                  </template>
                  <template #cell(payment_amount)="data">
                    {{ formatCurrency(data.value) }}
                  </template>
                  <template #cell(receive_from)="data">
                    {{ data.item.first_name }} {{ data.item.last_name }}
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="handlePageChange"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-card-text>

            <!-- user modal -->
            <b-modal
              id="app-modal"
              :title="is_update ? 'Update Payment Details' : 'Add Payment'"
              title-class="font-18"
              size="huge"
              hide-footer
              @hide="hide"
            >
              <div class="bp_form_box">
                <div class="bp_form_side_bar">
                  <div
                    v-for="(data, index) in tab_items"
                    :key="index"
                    class="bp_tab_link"
                    :class="is_tab_active === data.id ? 'bp_active' : ''"
                    @click="showFormContent(data.id)"
                  >
                    <div class="bp_tab_counter">
                      {{ index + 1 }}
                    </div>
                    <div class="bp_text_view">
                      <span> {{ data.title }} </span>
                    </div>
                  </div>
                </div>

                <div class="bp_form_view">
                  <div class="form_content">
                    <div class="tab_content" v-if="is_tab_active === 1">
                      <!-- row content start -->
                      <div class="row">
                        <div class="col-md-8">
                          <v-select
                            v-model="property"
                            label="property_name"
                            :filterable="false"
                            :options="options"
                            @search="onSearch"
                            class="borderless_input"
                          >
                            <template slot="no-options">
                              type to search property
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.property_name }}
                              </div>
                            </template>
                          </v-select>
                          <label
                            class="form-label mt-3 mb-3"
                            for="validationCustom04"
                            >Property</label
                          >
                        </div>
                      </div>
                      <!-- <div class="row">
                    <div class="col-md-4">
                      <input id="item1" v-model="payment.transaction_number" class="form-control borderless_input" placeholder="000 0000" type="text"
                        :disabled="!is_update"
                         />
                      <label class="form-label" for="item1">Transaction Number</label>
                    </div>

                    <div class="col-md-4"></div>

                    <div class="col-md-4">
                
                      <date-picker id="item1" v-model="payment.date" 
                      :class="{
                        'is-invalid': submitted && $v.payment.date.$error,
                      }"
                      format="DD MMMM, YYYY" class="borderless_input" lang="en"></date-picker>
                     
                      <label class="form-label" for="item1">Receive Date</label>
                      <div v-if="submitted && $v.payment.date.$error" class="invalid-feedback">
                      <span v-if="!$v.payment.date.required">This value is required.</span>
                    </div>
                    </div>

                  </div> -->
                      <!-- row content end -->

                      <!-- row content start -->
                      <div class="row">
                        <div class="col-md-6">
                          <v-select
                            v-model="tenant"
                            label="full_name"
                            :filterable="false"
                            :options="tenants"
                            @search="onTenantSearch"
                            class="borderless_input"
                          >
                            <template slot="no-options">
                              type tenant email to search tenant
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.full_name }}
                              </div>
                            </template>
                          </v-select>

                          <label class="form-label mt-2" for="item1"
                            >Receive From (tenant)</label
                          >
                        </div>

                        <div class="col-md-2"></div>

                        <div class="col-md-4 mt-2">
                          <input
                            id="item1"
                            v-model.lazy="formattedPaymentAmount"
                            class="form-control borderless_input"
                            :class="{
                              'is-invalid':
                                submitted && $v.payment.payment_amount.$error,
                            }"
                            type="text"
                           
                          />
                          <label class="form-label" for="item1"
                            >Payment Amount</label
                          >
                          <div
                            v-if="submitted && $v.payment.payment_amount.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.payment.payment_amount.required"
                              >This value is required.</span
                            >
                          </div>
                        </div>
                      </div>
                      <!-- row content end -->

                      <!-- row content start -->
                      <div class="row">
                        <div class="col-md-6">
                          <select
                            id="item1"
                            v-model="payment.purpose_of_payment"
                            class="form-select form-control borderless_input"
                            :class="{
                              'is-invalid':
                                submitted &&
                                $v.payment.purpose_of_payment.$error,
                            }"
                          >
                            <option
                              v-for="(item, index) in categories"
                              :key="index"
                              :value="item.id"
                              >{{ item.name }}</option
                            >
                          </select>

                          <label class="form-label" for="item1"
                            >Purpose of payment</label
                          >
                          <div
                            v-if="
                              submitted && $v.payment.purpose_of_payment.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.payment.purpose_of_payment.required"
                              >This value is required.</span
                            >
                          </div>
                        </div>

                        <div class="col-md-6"></div>
                      </div>
                      <!-- row content end -->

                      <!-- row content start -->
                      <div class="row">
                        <div class="col-md-5">
                          <select
                            id="item1"
                            class="form-select form-control borderless_input"
                            :class="{
                              'is-invalid':
                                submitted &&
                                $v.payment.form_of_payment.$error,
                            }"
                            v-model="payment.form_of_payment"
                            @change="formOfPaymentChange"
                          >
                            <option
                              v-for="({id, form_of_payment }, index) in form_of_payments"
                              :key="index"
                              :value="id"
                              >{{ form_of_payment }}
                            </option>
                          </select>
                          <label class="form-label" for="item1"
                            >Form of payment</label
                          >
                          <div
                            v-if="
                              submitted && $v.payment.form_of_payment.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.payment.form_of_payment.required"
                              >This value is required.</span
                            >
                          </div>
                        </div>

                        <div class="col-md-3">
                          <input
                            id="item1"
                            v-model="payment.payment_form_number"
                            class="form-control borderless_input"
                            type="text"
                            :class="{
                              'is-invalid':
                                submitted && $v.payment.payment_form_number.$error,
                            }"
                          />
                          <label class="form-label" for="item1">{{
                            payment_number_label
                          }}</label>
                          <div
                            v-if="submitted && $v.payment.payment_form_number.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.payment.payment_form_number.required"
                              >This value is required.</span
                            >
                          </div>
                        </div>

                        <div class="col-md-4">
                          <date-picker
                            id="item1"
                            v-model="payment.payment_date"
                            format="DD MMMM, YYYY"
                            class="borderless_input"
                            :class="{
                              'is-invalid':
                                submitted && $v.payment.payment_date.$error,
                            }"
                            lang="en"
                          ></date-picker>
                          <label class="form-label" for="item1">{{
                            payment_date_label
                          }}</label>
                          <div
                            v-if="submitted && $v.payment.payment_date.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.payment.payment_date.required"
                              >This value is required.</span
                            >
                          </div>
                        </div>
                      </div>
                      <!-- row content end -->

                      <!-- row content start -->
                      <div class="row">
                        <div class="col-md-12">
                          <input
                            id="item1"
                            v-model="payment.memo"
                            class="form-control borderless_input"
                            type="text"
                          />
                          <label class="form-label" for="item1">Memo</label>
                        </div>
                      </div>
                      <!-- row content end -->
                    </div>

                    <div class="tab_content" v-if="is_tab_active === 2">
                      <!-- row content start -->
                      <div class="row">
                        <div class="col-md-4">
                          <date-picker
                            id="item1"
                            v-model="payment.date_deposited"
                            format="DD MMMM, YYYY"
                            class="borderless_input"
                            :class="{
                              'is-invalid':
                                submitted && $v.payment.date_deposited.$error,
                            }"
                            lang="en"
                          ></date-picker>
                          <label class="form-label" for="item1"
                            >Date Deposited</label
                          >

                          <div
                            v-if="submitted && $v.payment.date_deposited.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.payment.date_deposited.required"
                              >This value is required.</span
                            >
                          </div>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2">
                          <select
                            id="item1"
                            class="form-select form-control borderless_input"
                            :class="{
                              'is-invalid':
                                submitted && $v.payment.deposited_by.$error,
                            }"
                            v-model="payment.deposited_by"
                            @change="handleDepositedByChange"
                          >
                            <option value="staff">Staff</option>
                            <option value="tenant">Tenant</option>
                          </select>
                          <label class="form-label" for="item1"
                            >Deposited by</label
                          >
                        </div>

                        <div class="col-md-5" style="margin-top: -8px;" v-if="payment.deposited_by === 'tenant'">
                          <v-select
                            v-model="tenant"
                            label="full_name"
                            :filterable="false"
                            :options="tenants"
                            @search="onTenantSearch"
                            class="borderless_input"
                            @input="handleTenantChange"
                          >
                            <template slot="no-options">
                              type tenant name to search tenant
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.full_name }}
                              </div>
                            </template>
                          </v-select>

                          <label class="form-label mt-2" for="item1"
                            >Tenant Name</label
                          >
                        </div>

                        <div class="col-md-5" style="margin-top: -8px;" v-if="payment.deposited_by === 'staff'">
                          <v-select
                            v-model="staff"
                            label="full_name"
                            :filterable="false"
                            :options="staffs"
                            @search="onStaffSearch"
                            class="borderless_input"
                            @input="handleStaffChange"
                          >
                            <template slot="no-options">
                              type staff name to search staff
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.full_name }}
                              </div>
                            </template>
                          </v-select>

                          <label class="form-label mt-2" for="item1"
                            >Staff Name</label
                          >
                        </div>
                      </div>
                      <!-- row content end -->

                      <!-- row content start -->
                      <div class="row">
                        <div class="col-md-5">
                          <date-picker
                            id="item1"
                            v-model="payment.date_confirmed"
                            format="DD MMMM, YYYY"
                            class="borderless_input"
                            :class="{
                              'is-invalid':
                                submitted && $v.payment.date_confirmed.$error,
                            }"
                            lang="en"
                          ></date-picker>
                          <label class="form-label" for="item1"
                            >Date Confirmed</label
                          >
                          <div
                            v-if="submitted && $v.payment.date_confirmed.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.payment.date_confirmed.required"
                              >This value is required.</span
                            >
                          </div>
                        </div>

                        <div class="col-md-2"></div>
                        <div class="col-md-5" style="margin-top: -8px;">
                          <v-select
                            v-model="confirmed_by_staff"
                            label="full_name"
                            :filterable="false"
                            :options="confirmed_by_staffs"
                            @search="onConfirmedByStaffSearch"
                            class="borderless_input"
                            @input="handleConfirmedByChange"
                          >
                            <template slot="no-options">
                              type staff name to search staff
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.full_name }}
                              </div>
                            </template>
                          </v-select>

                          <label class="form-label mt-2" for="item1"
                            >Confirmed By</label
                          >
                        </div>
                      </div>
                      <!-- row content end -->
                    </div>

                    <!-- <div class="tab_content" v-if="is_tab_active === 3">
                  <div class="pdf_viewer_file_box" @click="loadPDF" v-if="is_update">
                    <img :src="pdfImage" alt="pdf" />
                    <h6>{{getFileName(payment.receipt)}}</h6>
                    <div class="view_btn">View</div>
                  </div>
            
                  <b-modal id="pdf-modal" title="Invoice"
          title-class="font-18" size="xl" hide-footer>
          <iframe :src="pdfSrc" frameborder="0" width="100%" height="900"></iframe>
        </b-modal>
                  <VueFileAgent ref="vueFileAgent" :theme="'list'" :multiple="false" :deletable="true" :meta="true"
                    :accept="'image/*,.pdf,.docx,.doc,.xlx,.csv'" :maxSize="'10MB'" :maxFiles="14"
                    :helpText="'Choose images, pdf, docx, doc, xlx, csv file'" :errorText="{
                      type: 'Invalid file type. Only images, pdf, docx, doc, xlx and csv Allowed',
                      size: 'Files should not exceed 10MB in size',
                    }" 
                    @select="filesSelected($event)" 
                    @beforedelete="onBeforeDelete($event)"
                    @delete="fileDeleted($event)" 
                    v-model="fileRecords"
                    ></VueFileAgent>
                </div> -->
                  </div>
                  <div
                    class="button_view d-flex align-items-center justify-content-between"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      @click="hide"
                    >
                    Cancel
                    </button>
                    <button
                      type="button"
                      v-if="is_update"
                      class="btn btn-outline-danger"
                      @click="deletePaymentItem"
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-success"
                      @click="
                        is_update ? updatePaymentDetails() : savePayment()
                      "
                    >
                      Save
                    </button>
                  </div>

                  <!-- <div class="button_view d-flex align-items-center justify-content-between" v-if="is_tab_active === 2">
            <button type="button" class="btn btn-outline-secondary" @click="hide">Cancel</button>
            <button type="button" class="btn btn-outline-success" @click="saveProfilePassword">Save</button>
          </div> -->
                </div>
              </div>
            </b-modal>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </Layout>
</template>
