import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const paymentComputed = {
    ...mapState('payment', {
        payment:(state) => state.payments,
    }),
    ...mapGetters('payment', ['getPayment'])
}

// export property actions
export const paymentMethods = mapActions('payment', ['allPayment',
'allPaymentWithDateRange', 'allPaymentWithMonthYear', 'allPaymentForProperty','allPaymentForTenant',
'createPayment', 'updatePayment', 'deletePayment', 'markAsCancel'
])
